import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import Button from '../components/button'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import ProjectPostItem from '../components/project-post-item'
import { Link } from 'gatsby'

import { useValueTrackLogic } from '../hooks/useValueTrack'

import Breadcrumbs from '../components/breadcrumbs'

const BlogPostsLayoutContainer = styled.div`
  display: flex;
  padding: 2em 1em;
  flex-wrap: wrap;
  align-items: stretch;
`

const Heading = styled.div`
  padding: 3em 1em;
  padding-bottom: 5em;
  text-align: center;
  margin-bottom: -5em;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    margin-bottom: 0.15em;
  }
  p {
    color: #666;
    margin-bottom: 0.15em;
  }
`

const TestimonialsPage = (props) => {
  let { data } = props
  const hiddenVTRef = useValueTrackLogic({ isCampaign: 'siding' })
  const {
    contentfulProjectCategory: { name: title },
  } = data

  return (
    <Layout pageProps={props}>
      <div className="border-b border-gray-100">
        <nav
          className="flex container mx-auto px-4 py-3"
          aria-label="Breadcrumb"
        >
          <Breadcrumbs
            links={[
              { to: '/', label: 'Home' },
              { to: '/projects', label: 'Projects' },
              { to: props.location.pathname, label: title },
            ]}
          />
        </nav>
      </div>
      <SEO
        title={`Recent ${title} Projects - KYPD Kentucky Plumbing`}
        description={`Browse our photo gallery of recently completed ${title} projects.`}
      />
      <Heading>
        <h1>Recent {title} Projects</h1>
        <p>
          Browse our project gallery to see the projects we completed in
          Kentucky.
        </p>
        <div className="container mx-auto text-center py-2" ref={hiddenVTRef}>
          {[
            {
              node: {
                id: '1-all-items',
                name: 'All',
                slug: '',
              },
            },
            ...data.allContentfulTestimonialCategory.edges,
          ].map(({ node: { id, name, slug } }) => (
            <Link to={'/projects/' + slug} className="p-2">
              {name}
            </Link>
          ))}
        </div>
      </Heading>
      <BlogPostsLayoutContainer className="container">
        {data.allContentfulProjects.edges.map((item, i) => (
          <ProjectPostItem
            key={i}
            title={item.node.title}
            slug={item.node.slug}
            image={item.node.thumbnail}
            city={item.node.city}
            zipCode={item.node.zipCode}
            date={item.node.date}
            publishDate={item.node.publishDate}
            category={item.node.category.name}
            categorySlug={item.node.category.slug}
            author={null}
            page={'projects'}
            excerpt={item.node.metaDescription}
          />
        ))}
      </BlogPostsLayoutContainer>
    </Layout>
  )
}

export default TestimonialsPage

export const pageQuery = graphql`
  query getAllProjectsByCategory($slug: String!) {
    allContentfulTestimonialCategory {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    contentfulProjectCategory: contentfulTestimonialCategory(
      slug: { eq: $slug }
    ) {
      id
      name
    }
    allContentfulProjects(
      filter: { category: { elemMatch: { slug: { eq: $slug } } } }
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            name
            slug
          }
          date
          city
          zipCode
          thumbnail {
            gatsbyImageData(width: 585, height: 390, quality: 70)
          }
        }
      }
    }
    contactFormSideImage: contentfulAsset(
      title: { eq: "Bathroom Remodeling Seattle" }
    ) {
      id
      title
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`
